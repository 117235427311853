@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap');


* {
  margin: 0;
  padding: 0;
}


@-ms-viewport{
  width: device-width;
}


body::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar {
  width: 10.24px;
  border-radius: 7.48437px;
  background-color: #836e40;
}
::-webkit-scrollbar-thumb {
  background-color: #FFFDC3;
  border-radius: 7.48437px;
}

.answer{
  font-family: 'Montserrat';
  font-weight: 500;
}



.manrope-light {
  font-family: 'Manrope', sans-serif;
  font-weight: 500;
text-transform: uppercase;

color: #FFD664;
}
.inner-dummy {

  font-family: 'Montserrat';
  /* font-size: 1.5rem; */
  line-height: 22px;
  font-style: italic;
  color: #654A20;

  
  }



@font-face {
  font-family: batsand;
  src: url(Batsand.ttf);
}

.batsand {
  font-family: batsand;
  font-weight: 400;
  /* font-size: 10rem; */
  color: #A17602;

}

.batsand-dark {
  font-family: batsand;
  font-weight: 400;
  /* font-size: 10rem; */
  color: #443626;
 

}

.mint-text {
  font-family: 'Montserrat';
    color: rgba(101, 74, 32, 1);
    font-style: italic; 
    font-weight: 600;
  }
  

.poker {
  font-family: batsand;
  font-weight: 300;
  /* font-size: 10rem; */
  color: #A17602;
}

.poker-dark {
  font-family: batsand;
  font-weight: 300;
  /* font-size: 10rem; */
  color: #443626;
 
}

.join-dark {
  font-family: batsand;
  font-weight: 400;
  color: #8D682D;
  word-wrap: break-word;
}

.join {
  font-family: batsand;
  font-weight: 400;
  color: #443626;
  word-wrap: break-word;
}
.join-lorem {

  font-family: 'Montserrat';
  font-style: italic; 
  /* font-size: 1.5rem; */
  font-weight: 600;
  line-height: 30px;
  color: #261f16;
  /* word-break: break-all; */
  
}

.join-social {
  font-family: 'Montserrat';
  

  /* font-size: 1.5rem; */
  /* line-height: 22px; */
  /* font-style:normal; */
  color: #654A20;

}


.faq-bg {
  background: rgba(136, 103, 66, 0.53); 
}

.faq-question {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  /* font-size: 22px; */
  /* line-height: 27px; */
  /* identical to box height */
  
  /* text-align: center; */
  
  color: #443626;
}

.lorem {
  font-family: 'Montserrat';
  font-style: italic; 
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 30px;
  color: #261f16;
  /* word-break: break-all; */
  
}


.faq-lorem {
  font-family: 'Montserrat';
  font-style: italic; 
  /* font-size: 1.6rem; */
  font-weight: 600;
  /* line-height: 30px; */
  color: #261f16;
  word-break: break-all;
}

.team-lorem {
  font-family: 'Montserrat';
 
  font-size: 3.5rem;
  font-weight: 600;
  text-transform: uppercase;
  font-style: italic;
  text-align: center;
  color: #261f16;
  
}


.roadmap-bg {
  background-color: #F4D37C;
  /* z-index: 10; */
  
}
.div-road {
  background-color: #F4D37C;
  /* background-image: url("../public/assets/texture-1.png"); */
  /* background-repeat: no-repeat; */
  
}
/* .left-tree {
  position: absolute;
  background-image: url("../public/assets/left-tree.png");
  background-repeat: no-repeat;
  z-index: -100;
} */

.dummy-1 {
  font-family: batsand;
  font-weight: 400;
  font-size: 2.5rem;
  color: #443626;
  line-height: 60px;
}



/* .inside-div {
  background-image: url("../public/assets/texture-1.png");
} */

.box-1 {
  box-shadow: -8px 8px  rgba(63, 47, 29, 0.74);

  /* border-radius: 11px 10px 10px 100px; */
-webkit-border-radius: 20px 20px 20px 20px;

  
}

.border {
  border-color: #381F03;
  border-style: solid;
  border-radius: 20px 20px 20px 20px;
  border-width: 2.5px;

}

.faq {
  background: rgba(136, 103, 66, 0.53);
}



.multiple-bg {
  /* background-image: linear-gradient(to right, rgb(236, 199, 120), rgb(227, 178, 106)); */

}
.more-bg {
  /* background-color: rgb(236, 199, 120); */
  /* background-color: ["ebc379","f0da9c","ebcc7c","f1d48b","e4b46c","e4bc6c","eccc8c","e4b464","f4cc84"]; */
}



html, body {
  overflow-x:hidden;
  /* max-width: 100%; */
  scroll-behavior: smooth;
  padding: 0;
  margin: 0;
  background-color: #F4D37C;



 
}

body{
  font-weight: 500 !important;
}
/* * {
  outline: solid 1px red;
} */





img {
  background-size: cover;
}

/* .darkmode-button {
  position: absolute;
  right: -80%;
}


.nav {
  position: absolute;
  left: 32%;
  top: 1.9%;
  z-index: 100;
  
} */
.hr-div-1 {
  /* position: relative;
  width: 111.5%;
  margin-bottom: 1.5em; */
  border-color: #F4D37C;
  border-width: 1px;


}

.hr-div-2 {
  /* position: relative;
  width: 93%;
  left: 4%;
  margin-bottom: 1.5em; */
  border-color: #F4D37C;
  border-width: 1px;
}

video {
  z-index: -100;
}

/* .logo-div {

  width: 10%;
  position: absolute;
  left: 45%;
  right: 0%;
  bottom: 42%;
} */
.logo {
  object-fit: cover;
}

.parent {
  position: absolute;
  max-width: 100%;
  top: 10%;
  display: grid;
  grid-template-columns: repeat(5, 0.15fr) 0.1fr 0.2fr;
  grid-template-rows: 0.2fr;
  grid-column-gap: 26px;
  grid-row-gap: 0px;
  /* background-color: ; */

  width: 100%;
  }
  
  .div1 { 
    grid-area: 1 / 1 / 2 / 4;
    z-index: 100;
   
  }
  
  .div2 { 
  grid-area: 1 / 5 / 2 / 9;
  /* top: 60px; */
  position: relative;
    bottom: 6%;
  z-index: 100; 
  }

/* span {
  margin-bottom: -5%;
} */



  .disc-img {
    
    /* position: relative;
    top: 25%;
    left: 7%;
    width: 25px; */

  }
.twitter-img {
  /* position: relative;
    bottom: 18%;
    left: 22%;
    width: 25px; */
}
.os, .wallet {
  position: relative;
  bottom: 40.5%;

  /* left: 36%; */
}




.team-grid {
  
  display: grid;
grid-template-columns: repeat(3, 1fr);
grid-template-rows: repeat(2, 1fr);
grid-column-gap: 0px;
grid-row-gap: 0px;
  
}

.span-grid {
  display: grid;
grid-template-columns: repeat(3, 1fr);
grid-template-rows: repeat(2, 1fr);
grid-column-gap: 0px;
grid-row-gap: 0px;
}



.footer-bg {
  background-color: #BB9A5D;
}
.footer-text {
  font-family: 'Montserrat';
/* font-size: 1.8rem; */
font-style: normal;
font-weight: 300;
line-height:2.5rem;
/* text-align: left; */


  color: #FFFDC3;
}

.discord-text {
  font-family: 'Montserrat';
  font-style:normal;
  /* font-size: 1.5rem; */
  font-weight: 400;
  /* line-height:2.5rem; */
  color: #FFFDC3;
}

.email-box {
  background-color:#F4D37C;

}

.email-font {
  font-family: 'Montserrat';
/* font-size: 1.7rem; */
font-weight: 400;
color: #44362680;


}

.send-box {
  background: rgba(68, 54, 38, 0.7);
}

.send-font {
  font-family: 'Montserrat';
  /* font-size: 1.7rem; */
  font-weight: 400;
  
  color: #F4D37C;

}
.og-font {
  font-family: 'Montserrat';
/* font-size: 1.7rem; */
font-style: italic;
font-weight: 600;
line-height: 27px;
color:#443626;
}

.og-text {
  font-family: 'Montserrat';
/* font-size: 1.5rem; */
font-weight: 400;
line-height: 32px;
text-align: left;
color: #443626;

}


/* .squorble {
  position: relative;
 border-radius: 0.5em;
  width: 70%;
  background-color: rgb(154, 97, 134);
} */


/* .new {
  background-image: url("/public/assets/proper-morning-giphy.gif");
}

.new-2 {
  background-image: url("/public/assets/proper-night-giphy.gif");
}

.image-one {
  url
} */


.navbar{
  /* width: 100%; */
  cursor: pointer;

  background-color: #1D170F;
  display: flex;
  justify-content:flex-start;
  align-items:left;
}

.navbar .logo{
  color: #D9D2C3;
  font-size: 28px;
  font-weight: 600;
}

.navbar .links{
  list-style: none;
  /* display: flex; */
  color: #EBC677;
  cursor: pointer;
}



.navbar .toggle-icon{
  display: none
}

@media only screen and (min-width: 767px) and (max-width:768px) {
  
  .tablet {
    background-color: #EBC677;
    z-index: -10; 
    height: 280%;
  }

  .tablet-faq {
    margin-top: 66%;
  }

  .tablet-footer {
    margin-top: 60%;
  }

}


@media(min-width: 320px) and (max-width: 768px) {
  .navbar{
    position: relative;
    transition: 0.5s all ease-in-out;
    
  }

.team-grid {
  
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}


  .navbar.expanded{
    height: 50vh;
    width: 100vw;
    transition: 0.5s all ease-in-out;
  }

  .navbar.expanded .links{
    position: absolute;
    top: 100px;
    left: 20px;
    width: 40%;
    display: flex;
   
    opacity: 1;
  }

  .inner-div {
    
    z-index: -10;
    position: absolute;
    top: 0px;
    height: 60%;
    width:100vw;
    height: 50vh;

    border: 3px solid #836e40;
  }
  
li {
  position: relative;
  bottom: 40px;
  left: 6%;
  font-size: 1.5rem;
  text-align: left;
}


 

  .navbar .toggle-icon{
    display: block;
    color: #EFC34E;
    position: absolute;
    top: 28px;
    left: 20px;
  }

  .navbar .links{
    position: absolute;
    top: -300px;
    left: 20px;
    /* width: 100%; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    opacity: 0;
    transition: all 0.5s ease-in-out;
  }

  .navbar .links li{
    margin: 2px 0;
    
    
  }

  









}





@media(min-width: 360px) and (max-width: 428px) {
  
  .huawei-footer {
    margin-top: 200px;
  }
  
}